const our_products = [
  {
    id:2,
    slug: 'epos-solutions',
    img: "./images/products/epos.png",
    subtitle: "Epos Solutions",
    link: "#",
    content:
      "Our electronic point of sale (aka “ePOS” or “POS”) helps you run your business by managing your sales, payments, records.",
  },
  {
    id:5,
    slug: 'delivery-management',
    img: "./images/products/delivery.svg",
    subtitle: "Delivery Management",
    link: "#",
    content:
      " Delivery management is the process of ensuring goods are safely and efficiently shipped from one location to another.",
  },
  {
    id:4,
    slug: 'multi-channel-stores',
    img: "./images/products/store.png",
    subtitle: "Multi Channel Stores",
    link: "#",
    content:
      "Multi-channel retailing is a business strategy that offers your customers different sales channels to purchase.",
  },
  {
    id:1,
    slug: 'stock-management',
    img: "./images/products/stock.png",
    subtitle: "Real-Time Inventory",
    link: "#",
    content:
      "Optimize Sales Channels with Real-Time Inventory Management. Boost Profits, Reduce Costs.",
  },
  {
    id:3,
    slug: 'ecommerce',
    img: "./images/products/ecommerce.png",
    subtitle: "eCommerce",
    link: "#",
    content:
      "Ecommerce is the online buying and selling of goods and services, offering convenience and global reach, transforming the retail industry.",
  },
  {
    id:7,
    slug: 'payment-gateway',
    img: "./images/products/payment.png",
    subtitle: "Payment Gateways",
    link: "#",
    content:
      "We integrate your preferred payment gateway system (PayPal, Stripe, Worldpay, etc.) with your third-party APIs to leverage.",
  },
  {
    id:6,
    slug: 'reporting-and-forcasting',
    img: "./images/products/reporting.png",
    subtitle: "Reporting & Forcasting",
    link: "#",
    content:
      "Ecommerce demand forecasting is the method of estimating and predicting your business’ future demand for products.",
  },
];
export default our_products;
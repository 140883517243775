import React from "react";
import { NavLink } from "react-router-dom";
import "./Common.sass";
const RightImg = ({ data }) => (
  <div className="blog-des">
    <div className="row ">
      <div className="title text-center mb-0">{data.title}</div>
      <div className="rm-subtitle">{data.type}</div>
      <div className="d-flex justify-content-center align-items-center">
        <img
          src="./images/retailers.svg"
          alt=""
          className="img-b mb-4 d-md-none"
        />
      </div>
      <div className="col-md-8">
        <div className="content">{data.short_des}</div>

        <NavLink className="readmore text-end" to={"/blog/1"}>
          Learn More <i className="fa-solid fa-angle-right"></i>
        </NavLink>
      </div>
      <div className="col-md-4 d-flex justify-content-center align-items-center">
        <img
          src="./images/retailers.svg"
          alt=""
          className="img-b  d-none d-md-block"
        />
      </div>
    </div>
    <div className="date-b">
      <span>{data.date}</span> <span>{data.author}</span>
    </div>
  </div>
);

export default RightImg;

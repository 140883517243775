import React from "react";
import Description from "../components/about/Description";
import Banner from "../components/common/Banner";
import Testimonial from "../components/home/Testimonial";
import Main from "../layouts/main";
import Blog from "../components/home/Blog";
//import Integration from "../components/home/Integration";
//import Contact from "../components/home/Contact";
import serverConfig from "../config/api.json";
import axios from "axios";

const AboutUs = () => (
  <Main title="About Ecomaas" description="Discover Ecomaas, the platform dedicated to empowering multichannel eCommerce success. Learn about our commitment to innovation, customer satisfaction, and industry expertise. Join us on this journey of empowerment and unlock the true potential of your online business.">
    <div className="about-us">
      <Banner dataBanner={data} />
      <Description />
      <Testimonial data={data2} />
      <Blog />
      {/*<Integration />
      <Contact />*/}
    </div>
  </Main>
);
const data = {
  type: "about",
  title: "who we are",
  content: `<strong class="bold-text">eComaaS</strong> is your all-in-one solution for managing a 
    customer-focused <strong class="bold-text">eCommerce business.</strong> With powerful tools like 
    <strong class="bold-text">ePos</strong> for seamless transactions, <strong class="bold-text">inventory management</strong> 
    for optimized supply chains, and integration with multi-channel stores like <strong class="bold-text">Amazon</strong> and 
    <strong class="bold-text">eBay</strong>, you can reach a wider audience. Our robust 
    <strong class="bold-text">delivery management</strong> and <strong class="bold-text">reporting features</strong> 
    provide valuable insights, while secure <strong class="bold-text">payment gateways</strong> ensure a smooth checkout experience. 
    Whether you're a small store or a national chain, <strong class="bold-text">eComaaS</strong> streamlines operations for seamless success.`,
  /*link: "",
  button: "Request demo",*/
  img: "./images/banner-home.svg",
};

const fetchTestimonialData = async () => {
  let data = [];
  try {
    const response = await axios.get(`${serverConfig.api}/testimonials`);
    data = response.data.data;
    //setTestimonial(data);
    return data;
  } catch (error) {
    console.error("Error fetching testimonial data:", error);
    return data;
  }
};

const data2 = await fetchTestimonialData();

export default AboutUs;

import React from "react";
import "./Description.sass";

const Description = ({ desData }) => (
  <div className="si-des-section">
    <div className="row">
      <div className="col-md-5">
        <div className="d-flex justify-content-center align-items-center d-none d-md-block">
          <img
            src={process.env.PUBLIC_URL + desData.opt_img}
            alt=""
            className="si-img"
          />
        </div>
      </div>
      <div className="col-md-7">
        <div className="d-flex justify-content-center align-items-center">
          <div className="">
            <div className="title ">{desData.content_title}</div>
            <div className="des-contents">
              {desData.options.map((option, index) => (
                <div className="" key={index}>
                  <div className="d-flex flex-nowrap align-items-baseline list-row">
                    <img
                      src="../images/check_icon.svg"
                      alt=""
                      className="des-img-icon"
                    />
                    <div className="">
                      <div className="des-options">{option.name}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Description;

import React from "react";
import blog_data from "../../data/blog_data";
import LeftImg from "./LeftImg";
import RightImg from "./RightImg";
const BlogShort = () => (
  <div className="">
    {blog_data.map((blog, index) =>
      blog.img_right ? (
        <RightImg data={blog} key={index} />
      ) : (
        <LeftImg data={blog} key={index} />
      )
    )}
  </div>
);

export default BlogShort;

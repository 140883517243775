import React from "react";
import "./Description.sass";
const Description = () => (
  <div className="aboutus-description">
    <div className="row">
      <div className="col-12 col-md-7">
        <img src="./images/about-des.svg" alt="" className="aboutus-img"/>
      </div>
      <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
        <div className="">
          <p className="content">
            Key features of eComaaS include:
          </p>
            <ul className="content-list">
                <li>Seamless transactions with ePos</li>
                <li>Optimized inventory management</li>
                <li>Integration with multi-channel stores</li>
                <li>Robust delivery management system</li>
                <li>Accurate reporting and forecasting capabilities</li>
                <li>Integration with secure payment gateways</li>
            </ul>    
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center align-items-center">
        <p className="content">
            With <strong className="bold-text">eComaaS</strong>, you can efficiently handle <strong className="bold-text">order fulfillment</strong> and <strong className="bold-text">shipping processes</strong>, gain valuable insights into your business's performance, and ensure a smooth and secure checkout experience for your customers. Whether you operate a small cash and carry store or a national retail chain, <strong className="bold-text">eComaaS</strong> provides effective solutions to streamline your operations and drive long-term success.
        </p>
        </div>
    </div>
  </div>
);

export default Description;

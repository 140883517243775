import React from "react";
import Banner from "../components/common/Banner";
import Integration from "../components/home/Integration";
import Main from "../layouts/main";

const Pricing = () => (
  <Main title="Ecomaas Pricing" description="Explore Ecomaas Pricing page and discover our transparent and flexible multichannel eCommerce solutions tailored to meet your business needs">
    <div className="pricing">
      <Banner dataBanner={data} />
      {/* <Card /> */}
      <Integration />
    </div>
  </Main>
);

const data = {
  type: "common",
  title: "Affordable and flexible pricing options tailored to your needs.",
  //subtitle: "Transparent pricing plans to suit businesses of all sizes.",
  link: "/contact/#contact-frm",
  img: "./rafiki.svg",
  button: "Request demo",
};

export default Pricing;

import React from "react";
import { useEffect, useState } from "react";
import "./Contact.sass";
import axios from "axios";
import { useLocation } from "react-router-dom";
import serverConfig from "../../config/api.json";
import ReCAPTCHA from 'react-google-recaptcha';


const Contact = () => {
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const handleOnSubmit = async () => {
    //   if (!recaptchaToken) {
    //     alert('Please complete the reCAPTCHA');
    //     return;
    // }
    try {
      const response = await axios.post(`${serverConfig.api}/contact-us`, {
        first_name: first_name,
        last_name: last_name,
        email: email,
        msg: message,
        recaptchaToken: recaptchaToken,
      });
      setSuccessMessage(response.data.message);
      setErrors({});
    } catch (error) {
      setSuccessMessage(false);
      if (error.response.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div
      className={
        location.pathname = "/contact" ? `contact margin-contact` : `contact `
      }
    >
      <div className="blur-box-container-contact">
        <div className="common-blur-box-content common-blur-06">
          <img
            src="../img/Vector2.svg"
            alt=""
            className="common-blur-box-defalut common-blur-1"
          />
        </div>
      </div>
      <div className="title" id="contact-frm">
        Contact Us
      </div>
      <div className="row">
        <div className="col-md-6">
          <form className="contact-form">
            {/*<div className="cf-subtitle">get Connected</div>
            <div className="contact-des">
              We value your feedback, inquiries, and suggestions, and we're here
              to assist you in any way we can. Whether you have a question about
              our products or services, need assistance with an order, or simply
              want to share your thoughts, this is the place to reach out to us.
    </div>*/}
            <div className="row">
              {successMessage && (
                <div className="alert alert-success contact-msg alert-dismissible auto-hide-notification mt-30 text-center">
                  {successMessage}
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  <i className="icon-info"></i> There was a problem with your
                  submission.
                  <ul>
                    {Object.entries(errors).map(([key, value]) => (
                      <li className="error" key={key}>
                        {value[0]}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="col-md-6">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control txta-height w-100 light-gray-border"
                name=""
                id=""
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
              ></textarea>
            </div>
            <div className="mb-3">
                <ReCAPTCHA
                  sitekey="6Lf-YjQqAAAAAMiT6Wy0AsP_qsG43Kv7O4is0LQz"  
                  onChange={handleRecaptchaChange}
                />
              </div>

            <button type="button" className="submit" onClick={handleOnSubmit}>
              submit
            </button>
          </form>
        </div>
        <div className="col-md-6">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/images/contact_back.png"}
              alt=""
              className="contact-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

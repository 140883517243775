import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Testimonial.sass";

const options = {
  loop: true,
  margin: 5,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  center: true,
  nav: true, 
  navText: ["<i class='fa-solid fa-chevron-left'></i>", "<i class='fa-solid fa-chevron-right'></i>"], 
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 3,
    },
  },
};

const Testimonial = ({ data }) => {
  return (
    <div className="testimonial">
      <section id="slider">
        <div className="container fluid">
          <div className="title testimonial-head">What Our Client Says</div>
          <div className="slider">
            <OwlCarousel className="owl-carousel owl-home" {...options}>
              {data.map((test, index) => (
                <div className="slider-card" key={index}>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="">
                      <div className="t-title">{test.name}</div>
                      <div className="country">{test.location}</div>
                      <div className="d-flex justify-content-start">
                        <i className="fa-solid fa-quote-left q-icon"></i>
                      </div>
                      <div className="t-content">{test.body}</div>
                      <div className="d-flex justify-content-end">
                        <i className="fa-solid fa-quote-right q-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
      <div className="d-flex">
        <img
          src="./images/testimonial_back.svg"
          alt=""
          className="bottom-img"
        />
      </div>
    </div>
  );
};

export default Testimonial;

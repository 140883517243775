import React from "react";
import "./Description.sass";

const Description = ({ listData }) => (
  <div className="sp-des">
    <div className="title">{listData.title}</div>
    <div className="subtitle">{listData.subtitle}</div>
    <div className="row">
      <div className="col-md-6">
        <div className="d-flex justify-content-center align-items-center">
          <div className="content" dangerouslySetInnerHTML={{__html:listData.content}}></div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="d-flex justify-content-center align-items-center">         
          <img src={listData.img} alt="" className="spo-des-img" />
        </div>
      </div>
    </div>
  </div>
);

export default Description;


const product = [
    {
      type: "spro",
      subtitle: "How does our inventory management system streamline processes and improve efficiency for businesses in tracking, organizing, and managing their inventory?",
      options: [
        {
          name: "Automate Purchase Orders: Streamline inventory management effortlessly.",
        },
        {
          name: "Custom Barcode Printing: Simplify tracking with custom labels.",
        },
        {
          name: "Generate Reports Easily: Gain insights for informed decisions.",
        },
        {
          name: "Organize Inventory Efficiently: Group items for better organization.",
        },
        {
          name: "Monitor Stock Variances: Ensure accurate stock levels with detailed reports.",
        },
      ],
      id: 1,
      slug: 'stock-management',
      img: "../images/checking boxes.png",
      listone: [
        {
          title: "Automate purchase orders and replenishment",
          // subtitle:
          //   "Track and Control inventory from any channel and in any warehouse",
        },
        {
          title: "Create and print custom barcodes or QR labels",
          // subtitle: "Dynamic inventory forecasting tool",
        },
        {
          title:
            "Effortlessly create reports to share",
          // subtitle:
          //   "21+ direct integrations ensure your inventory is always accurate and up to date.",
        },
        {
          title: "Group items by category, location, conditions etc",
          // subtitle:
          //   "bulk point lables, ship your orders & track your inventory in one place",
        },
      ],
      listtwo: [
        {
          title: "View variance reports on stock expected versus stock delivered",
          // subtitle:
          //   "Setup workflows based on triggers like delivery option selected and weight",
        },
        {
          title: "Import data via CSV",
          // subtitle:
          //   "generate inventory, sales and fullfillment reports for decision",
        },
        {
          title: "Receive goods in against purchase orders",
          // subtitle:
          //   "having your inventory and shipping under one roof helps speed up your fullfillment",
        },
        {
          title: "Set minimum stock holding quantities for each location",
          // subtitle:
          //   "upgrade your fullfillment one step further with digital picking user our scanner",
        },
      ],
      description:{
          title:"Real-Time inventory",
          subtitle:"Enhance Your Business with Real-Time Inventory Management:",
          img:'../images/spdesimg1.svg',
          content:`Experience seamless <b>integration with your ecommerce platforms </b>
                    (WooCommerce, Amazon, eBay, etc.), wholesale, EDI, and consumer sales channels. 
                    <br></br>

                    <ul>
                      <li>Optimize operations and <b>lower costs</b> by managing inventory in real-time across all sales channels.</li>
                      <li><b>Minimize dead stock</b> and maximize sales and profits through accurate inventory tracking.</li>
                      <li>Select from a range of <b>automations</b> to improve fulfillment efficiency and further reduce operating costs.</li>
                    </ul>`
        }
    },
    {
      type: "spro",
      subtitle: "How does eComaaS retail ePOS system improve efficiency, support business growth, and provide real-time inventory control and seamless integration with ecommerce channels?",
      options: [
        {
          name: "Seamless Online/Offline Functionality: Manage your business anywhere, anytime.",
        },
        {
          name: "Real-Time Inventory Control: Stay in control with live updates.",
        },
        {
          name: "Cross-Device Compatibility: Access your system from any device.",
        },
        {
          name: "Effortless Ecommerce Integration: Streamline your online sales process.",
        },
        {
          name: "Rapid Staff Training: Get up to speed quickly with an intuitive interface.",
        },
      ],
      id: 2,
      slug: 'epos-solutions',
      img: "../images/credit card.png ",
      listone: [
        {
          title: "Cloud-based retail ePos works online / offline and across multi-locations. ",
          // subtitle:
          //   "Track and Control inventory from any channel and in any warehouse",
        },
        {
          title: "Real time inventory control and stock management",
          // subtitle: "Dynamic inventory forecasting tool",
        },
        {
          title:
            "Works on Windows, iPad and Android devices. ",
          // subtitle:
          //   "21+ direct integrations ensure your inventory is always accurate and up to date.",
        },
        {
          title: "Seamless integration with ecommerce channels ",
          // subtitle:
          //   "bulk point lables, ship your orders & track your inventory in one place",
        },
      ],
      listtwo: [
        {
          title: "Train staff in minutes with our easy to use ePos ",
          // subtitle:
          //   "Setup workflows based on triggers like delivery option selected and weight",
        },
        {
          title: "Scales with your business terminals as you need them add new retail ePos",
          // subtitle:
          //   "generate inventory, sales and fullfillment reports for decision",
        },
        {
          title: "Purchase orders and demand planning ",
          // subtitle:
          //   "having your inventory and shipping under one roof helps speed up your fullfillment",
        },
        {
          title: "Advanced retail reporting and insight tools",
          // subtitle:
          //   "upgrade your fullfillment one step further with digital picking user our scanner",
        },
      ],
      description:{
          title:"Epos Solutions",
          subtitle:"Transform Your Business Operations with Our Powerful ePOS System!",
          img:'../images/spreetsheet.png',
         
          content:`   
          Our <b>electronic point of sale (ePOS or POS)</b> system is your ultimate solution for managing sales, payments, records, inventory, and more with ease.<br></br>

          Businesses upgrade to EPOS from traditional tills for the robust software that drives the system. It <b>integrates industry-specific tools</b> and apps to enhance operations and drive growth, while still effortlessly accepting card and cash payments.<br></br>

          We've <b>customized our ePOS system</b> for easy access. No installation needed - simply access it via a browser from anywhere in the world!`
      }
    },
    {
      type: "spro",
      subtitle: "How does our backend system streamline the order processing and fulfillment process to ensure efficient and seamless customer experiences?",
      options: [
        {
          name: "Customer Order Placement: Customers select products/services on our website and place orders.",
        },
        {
          name: "Order Relay to Backend System: Order details are relayed to our central backend system.",
        },
        {
          name: "Order Processing: Our backend system manages order validation, inventory, payments, and fraud detection.",
        },
        {
          name: "Payment Confirmation: Customers receive instant payment and order confirmation.",
        },
        {
          name: "Fulfillment and Delivery: The shipping department fulfills orders promptly.",
        },
      ],
      id: 3,
      slug: 'ecommerce',
      img: "../images/ecommerce.png",
      listone: [
        {
          title: "Customize and extend ",
          // subtitle:
          //   "Track and Control inventory from any channel and in any warehouse",
        },
        {
          title: "Run your store from anywhere",
          // subtitle: "Dynamic inventory forecasting tool",
        },
        {
          title:
            "Simplify ecommerece management",
          // subtitle:
          //   "21+ direct integrations ensure your inventory is always accurate and up to date.",
        },
        {
          title: "Fast bulk listing of your products to your eCommerce",
          // subtitle:
          //   "bulk point lables, ship your orders & track your inventory in one place",
        },
      ],
      listtwo: [
        {
          title: "Maintain profile/order history and order tracking",
          // subtitle:
          //   "Setup workflows based on triggers like delivery option selected and weight",
        },
        {
          title: "Multiple payment modes",
          // subtitle:
          //   "generate inventory, sales and fullfillment reports for decision",
        },
        {
          title: "Plenty of different categories and subcategories",
          // subtitle:
          //   "having your inventory and shipping under one roof helps speed up your fullfillment",
        },
        {
          title: " Multiple delivery option",
          // subtitle:
          //   "upgrade your fullfillment one step further with digital picking user our scanner",
        },
        
      ],
      description:{
          title:"Ecommerce",
          subtitle:" Streamline Your Ecommerce Experience with Our Customizable Solutions",
          img:'../images/ecommerce-2.png',
          content:` Our platform empowers you to <b>manage your store from anywhere,</b> ensuring you're 
                    always connected and in control. With intuitive tools, we simplify the <b>complexities 
                    of ecommerce management,</b> saving you time and effort. Easily <b>list your products in bulk </b>
                    to your eCommerce platform, accelerating the process and allowing you to focus on
                     business growth.
                  <br /><br />  
                  Keep track of your profile details, order history, and track orders effortlessly, 
                  ensuring a seamless shopping experience for your customers. Accept <b>payments through 
                  multiple modes,</b> providing convenience and flexibility while boosting sales. Organize 
                  your products effectively with plenty of categories and subcategories, making it easier
                   for customers to find what they're looking for.

                  <br /><br /> 
                  Offer flexibility with multiple delivery options, ensuring timely and convenient 
                  delivery of purchases.`
      }
    },
    {
      type: "spro",
      subtitle: "How does our solution streamline multi-channel management and enhance operational efficiency for businesses?",
      options: [
        {
          name: "Manage All Your Channels: Handle all sales channels from one intuitive dashboard.",
        },
        {
          name: "Bulk Marketplace Listing: Streamline product listings across multiple marketplaces.",
        },
        {
          name: "Real-Time Order Management: Track sales orders in real time from one dashboard.",
        },
        {
          name: "Product Data Management: Easily manage product data, pricing, images, and attributes.",
        },
        {
          name: "Automatic Inventory Sync: Keep inventory levels updated across all sales channels.",
        },
      ],
      id: 4,
      slug: 'multi-channel-stores',
      img: "../images/multichannel.png",
      listone: [
        {
          title: " Manage all your channels from onr dashboard",
          subtitle:
            "",
        },
        {
          title: "Bulk marketplace listing",
          subtitle: "",
        },
        {
          title:
            "Sales orders arrive in real time, into one dashboard",
          subtitle:
            "",
        },
        {
          title: "Create and manage all your product data including pricing,images, and attributes",
          subtitle:
            "",
        },
      ],
      listtwo: [
        {
          title: "Automatically synchronize inventory ",
          subtitle:
            "",
        },
        {
          title: "Schedules sales order processing to achieve pick,pack and dispatch efficiency",
          subtitle:
            "",
        },
        {
          title: "Custom reports we created",
          subtitle:
            "",
        },
      ],
      description:{
          title:"Multi Channel Stores",
          subtitle:"Unlock Growth Opportunities with Multi-Channel Retailing",
          img:'../images/multichannel2.png',
          content:`Multi-channel retailing offers customers diverse purchasing options, including <b>physical stores, online platforms</b> like WooCommerce, and third-party marketplaces such as Amazon and eBay. 
          <br></br>
          This strategy accommodates varying customer preferences and behaviors, allowing businesses to expand their reach and <b>enhance the shopping experience.</b>
          `
      }
    },
    {
      type: "spro",
      subtitle: "How does our delivery management system enhance efficiency and optimize the shipping process for better customer experience?",
      options: [
        {
          name: "Integrate Multiple Delivery Partners: Offer customers more shipping choices.",
        },
        {
          name: "Dynamic Checkout Choices: Enhance checkout with dynamic options. ",
        },
        {
          name: "Automate Processes: Streamline operations and reduce errors.",
        },
        {
          name: "Real-Time Delivery Tracking: Monitor deliveries and assess carrier performance.",
        },
        {
          name: "Expedited Click and Collect: Speed up transactions with quick order pickup.",
        },
      ],
      id: 5,
      slug: 'delivery-management',
      img: "../images/delivery.png",
      listone: [
        {
          title: " Integrate multiple delivery partners",
          subtitle:
            "",
        },
        {
          title: "Dynamic checkout options",
          subtitle: "",
        },
        {
          title:
            "Eliminate manual errors",
          subtitle:
            "",
        },
        {
          title: "Track deliveries & view carrier performance",
          subtitle:
            "",
        },
      ],
      listtwo: [
        {
          title: "Speed up click and collect",
          subtitle:
            "",
        },
        {
          title: "Best carrier every time",
          subtitle:
            "",
        },
        {
          title: "Unified delivery tracking",
          subtitle:
            "",
        },
        {
          title: "Easy shipping label printing",
          subtitle:
            "",
        },
      ],
      description:{
          title:"Delivery Management",
          subtitle:"Efficient Delivery Management: Enhancing Your Shipping Process",
          img:'../images/dlivery2.png',
          content:` Delivery management, synonymous with fleet management or dispatch, is vital for ensuring the safe and <b>efficient shipment of goods</b> from one location to another.
          <br></br>
            Integrated with leading delivery services like Royal Mail, DPD, Parcel Force, and DHL, eComaaS streamlines the shipping process.
          <br></br>
          Our customizable delivery services consider factors like delivery postcode, product weight, dimensions, and price, ensuring tailored and cost-effective shipping solutions for your business.
          `
      }
    },
    {
      type: "spro",
      subtitle: "How do eComaaS reporting and forecasting tools drive sales growth, reduce costs, and provide insights into business performance and future demand?",
      options: [
        {
          name: "Comprehensive Business Reporting: Drive growth and cut costs with our forecasting tools.",
        },
        {
          name: "Big-Picture Sales Dashboards: Access comprehensive business reporting in one suite.",
        },
        {
          name: "Profit-Driven Supplier Insights: Gain detailed sales insights with clickable dashboards.",
        },
        {
          name: "Effective Performance Optimization: Optimize performance and forecast demand efficiently.",
        },
        {
          name: "Track Key Performance Indicators (KPIs): Monitor essential metrics for success and improvement.",
        },
      ],
      id: 6,
      slug: 'reporting-and-forcasting',
      img: "../images/spro1.svg",
      listone: [
        {
          title: "Drive sales growth and cut operating expense",
          subtitle:
            "",
        },
        {
          title: "Extensive reporting suite and insight tools",
          subtitle: "",
        },
        {
          title:
            "Access reporting across your entire business in one reporting suite",
          subtitle:
            " ",
        },  
      ],
      listtwo: [
        {
          title: "Big picture dashboards with click through for granular detail",
          subtitle:
            "",
        },
        {
          title: "Understand which suppliers and categories drive profit accross each channel",
          subtitle:
            "",
        },
      ],
      description:{
          title:"Reporting & Forecasting ",
          subtitle:"Maximize Sales Potential with Forecasting",
          img:'../images/reporting2.png',
          content:` Forecasting involves predicting future product or service demand to <b>anticipate sales trends</b> effectively.
          <br></br>
          Understanding these trends gives businesses a competitive edge by informing strategic decisions.
          <br></br>
          The ultimate aim is to establish accurate benchmarks for managing cash flow, inventory levels, and marketing strategies.
          <br></br>
          We streamline this process by integrating your preferred reporting tools and analyzing sales data to <b>provide insights into revenue, profit, and loss.</b>
          `
      }
    },
    {
      type: "spro",
      subtitle:"How does eComaaS payment solution enhance customer experience and streamline business operations?",
      options: [
        {
          name: "Multiple Payment Options: Offer diverse payment methods for a seamless shopping experience.",
        },
        {
          name: "Easy Integration: Seamlessly integrate payment options for a smooth checkout process.",
        },
        {
          name: "Invoicing Options: Streamline billing with customizable invoices and easy payment tracking.",
        },
        {
          name: "Detailed Reporting: Access comprehensive analytics for informed decision-making.",
        },
        {
          name: "Fast Processing Speed: Ensure efficient payment processing for quick fund transfers.",
        },
      ],
      id: 7,
      slug: 'payment-gateway',
      img: "../images/payment.png",
      listone: [
        {
          title: " Multiple payment options",
          subtitle:
            "",
        },
        {
          title: "Easy integration",
          subtitle: "",
        },
        {
          title: "Invoicing options",
          subtitle: "",
        },
      ],
      listtwo: [
        {
          title:
            "Detailed reporting",
          subtitle:
            "",
        },
        {
          title: "Fast processing speed",
          subtitle:
            "",
        },
      ],
      description:{
          title:"Payment gateways",
          subtitle:"Tailored Payment Solutions for Your Business",
          img:'../images/payment2.png',
          content:`We seamlessly <b>integrate your preferred payment gateway system </b>(PayPal, Stripe, Worldpay, etc.) with third-party APIs, ensuring direct payments on your web, mobile, or app platforms.
          <br></br>
          In cases where a standard integration falls short, we offer <b>custom payment gateway development</b> or add customized features to our third-party payment gateway integrations, enhancing flexibility, security, and functionality.
          <br></br>
          Our dedicated team of software engineers specializes in developing custom payment gateways, facilitating a seamless development process for rapid deployment, increased efficiency, and optimized user experience. With our tailored solutions, you can confidently navigate the complexities of payment processing while delivering an exceptional customer payment experience.
          `
      }
    },
  ];
  export default product;

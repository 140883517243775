import React from "react";
import Banner from "../components/common/Banner";
import Main from "../layouts/main";
import { useParams } from "react-router-dom";
import product from "../data/product";
//import Tool from "../components/SingleProduct/Tool";
import Description from "../components/SingleProduct/Description";
//import Demo from "../components/home/Demo";
import Contact from "../components/home/Contact";
function SingleProduct() {
  let { slug } = useParams();
  let index = product.findIndex((e) => e.slug === slug);
  let data = product[index];

  return (
    <div className="single-product">
      <Main>
        <Description listData={data.description}/> 
        <Banner dataBanner={data} />
        {/*<Tool listData={data}/>*/}    
        {/*<Demo />*/}
        <Contact />
      </Main>
    </div>
  );
}

export default SingleProduct;

import React from "react";
import "./Retailer.sass";
const Retailers = () => (
  <div className="retailers">
    <div className="row">
      <div className="col-md-7">
        <div className="title">
          ePos, Stock Control and Multichannel eCommerce for Retailers
        </div>
      </div>
      <div className="col-md-5"></div>
      <div className="col-12 col-md-5 mb-4 d-md-none">
        <img src="./images/retailers.svg" alt="" />
      </div>
      <div className="col-12 col-md-7">
        <p className="content">
        Managing a thriving multichannel business just got simpler. 
        eComaaS empowers retailers of all sizes with a comprehensive suite of tools to streamline 
        operations and maximize sales.
        </p>
        <a className="readmore" href="/products" target="_blank">
          Click here to check out the tools <i className="fa-solid fa-angle-right"></i>
        </a>
      </div>
      <div className="col-12 col-md-5 d-none d-md-flex">
        <img src="./images/retailers.svg" alt="" />
      </div>
    </div>
  </div>
);

export default Retailers;

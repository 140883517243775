import React from "react";
import BlogShort from "../components/blog/BlogShort";
import Related from "../components/blog/Related";
import Banner from "../components/common/Banner";
import Main from "../layouts/main";
import Contact from "../components/home/Contact";

const Blog = () => (
  <Main>
      <Banner dataBanner={data} />
      <div className="blog-page">
      
      <BlogShort />

      <Related />
      <Contact />
    </div>
  </Main>
);
const data = {
  type: "common",
  title: "get to know ",
  subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida urna nunc ut diam vitae. Felis consectetur vitae nec ac mattis ac egestas.",
  link: "",
  button: "Request demo",
  img:"./images/banner_blog.svg"
};

export default Blog;

import React from "react";
//import Banner from "../components/common/Banner";
import Contact from "../components/home/Contact";
import Main from "../layouts/main";


const ContactUs = () => (
  <Main title="Reach Out for Multichannel eCommerce Solutions" description="Get in touch with Ecomaas for all your multichannel eCommerce needs. Contact us to explore our comprehensive suite of tools, integrations, and services designed to streamline your online business operations. ">
    <div className="contactus">
      <div className="C_Para">
          For further information, please don't hesitate to contact us at <a href="tel:+44 203 5001380" className="C_link">+44 203 5001380</a> or 
          via email at <a href="mailto:contact@ecomaas.co." className="C_link">contact@ecomaas.co.</a> Alternatively, you can fill out the contact form below,
          and we'll get in touch with you as soon as possible.
      </div>
    {/*<Banner dataBanner={data} />*/}
      <Contact />
    </div>
  </Main>
);

/*const data = {
  type: "common",
  title: "we'd love to hear from you",
  subtitle:
    "whether you have questions about our features, trials, demo, pricing or anything else, our team is ready to answer your questions",
  link: "/contact/#contact-frm",
  button: "Request demo",
  img:"../images/banner-contact.svg"
};*/

export default ContactUs;

const navmenu=[
    {
        title:"Home",
        link:"/"
    },
    {
        title:"Ecosystem",
        link:"/ecosystem"
    },
    {
        title:"Products",
        link:"/products",
        submenu:[
            {
                title:"ePos Solutions",
                link:"/products/epos-solutions"
            },
            {
                title:"Stock Management",
                link:"/products/stock-management"
            },
            {
                title:"eCommerce",
                link:"/products/ecommerce"
            },
            {
                title:"Multi Channel Stores",
                link:"/products/multi-channel-stores"
            },
            {
                title:"Delivery Management",
                link:"/products/delivery-management"
            },
            {
                title:"Reporting & Forcasting",
                link:"/products/reporting-and-forcasting"
            },
            {
                title:"Payment Gateways",
                link:"/products/payment-gateway"
            }
        ]
    },
    {
        title:"Integrations",
        link:"/integrations"
    },
    {
        title:"Pricing",
        link:"/pricing"
    },
    {
        title:"About Us",
        link:"/about"
    },
    {
        title:"Contact Us",
        link:"/contact"
    },
]

export default navmenu;
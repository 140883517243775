import React from "react";
import "./Layers.sass";
const Layers = () => (
  <div className="layer-content">
    <div className="layers position-relative Layer_1">
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        <div className="box1 d-flex justify-content-center align-items-center div">
          Ecommerce <br /> Website
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          Inventory <br /> Management
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          E-POS
        </div>
      </div>
    </div>

    <div className="layers position-relative Layer_2">
      <div className="l-text position-absolute ">Integrations</div>
      <div className="box3 d-flex flex-wrap justify-content-center align-items-center">
        <div className="box1 d-flex justify-content-center align-items-center div">
          Amazon
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          Ebay
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          WooCommerce
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          Xero
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          Royal Mail
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          Stripe
        </div>
        <div className="box1 d-flex justify-content-center align-items-center div">
          Paypal
        </div>
      </div>
    </div>
  </div>
);

export default Layers;

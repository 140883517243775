const blog_data = [
  {
    title: "The importance of EPOS systems and future proofing your busines",
    short_des:
      "Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data.",
    content: `Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.

        The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data. Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.

        The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data. Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.

        The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data.`,
    link: "",
    date:"JAN 10, 2022",
    author:"Erin Rhiel Madsen",
    type:"Technology",
    img_right:true,
    id:1,
    img:"../images/blog/blog1.svg"
  },
  {
    title: "How can I utilise EPOS to future-proof my own business?",
    short_des: `Integrating an EPOS system with your e-Commerce store essentially helps you stay ahead of the curve against your competitors. With their efficient data management, you can begin tracking behavior patterns with these detailed analytical reports to begin targeting your audience with a methodological approach, allowing you to compile a detailed marketing strategy to see a solid return of investment from a valuable audience.

    This real-time insightful data is invaluable data, helping you seize an opportunity to fill a gap in the market, and continuing this by predicting future demands of your customers and making sure you’re catering to them. Essentially, using your EPOS system as a retail management system to help future proof your business.
    
    Having seen the impacts of the last years with our shopping habits. More of us are shopping online, therefore it’s key to ensure you have an efficient and working system that works in tandem with your website for optimum engagement. In short, take notes and make changes to establish business growth.`,
    content: `Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.

        The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data. Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.

        The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data. Do you currently sell online and offline, or perhaps you’re thinking of making that all-important integration between your online and your standalone brick-and-mortar shop?Thousands of businesses are already taking advantage of the power of an effective EPOS (Electronic Point-of-Sale) system to help grow their businesses, and so should you.EPOS systems are a combination of hardware and software and can help make a huge difference to your website once integrated with your online physical store.

        The software tracks your sales, inventory, and customer base, helping you make more informative and efficient business decisions by generating detailed reports in response to customer interactions with your store. Whereas the hardware accounts for the till system, payment terminals, barcode and QR code scanners supported by this software. Where traditional till systems simply record a sale, EPOS systems go that step further by recording an extensive backlog of detailed information helping you understand your customer base and their buying habits, offering an efficient inventory and accounts database, allowing you to build an effective business strategy supported by valuable methodical data.`,
    link: "",
    date:"JAN 10, 2022",
    author:"Erin Rhiel Madsen",
    type:"Technology",
    img_right:false,
    id:2,
    img:"../images/blog/blog1.svg"
  },
];

export default blog_data;

import React from "react";
import "./Banner.sass";
import "../../static/css/blurBox.sass";
import Home from "../common/Newsletter";

<Home />;

const HideImage = () => {
  const location = window.location.pathname;
  return location === "/pricing"
    ? "image-common-section d-none"
    : "image-common-section";
};

const SinglePro = ({ dataC }) => (
  <div className="row">
    <div className="SinglePro-subtitle col-12">{dataC.subtitle}</div>
    <div className="col-12 col-md-5">
    <div className="image-section-spro">
      <img className="singlePro_img" src={dataC.img} alt=""  />
    </div>
  </div>
      <div className="col-12 col-md-7">
        <div className="s-pro-contents">
          <div className="pro-contents">
            {dataC.options.map((option) => (
              <div className="d-flex flex-nowrap align-items-center">
                {/*<img src="../images/check_icon.svg" alt="" className="pro-img-icon" />*/}
                <div className="pro-options">
                  <ul>
                    <li>{option.name}</li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
      </div>
    {/* <form className="d-flex">
      <input
        className="form-control"
        type="search"
        placeholder="enter your email"
        aria-label="Search"
      />
      <button className="submit" type="submit">
        Search
      </button>
    </form>

    <p className="mail-inform">
      by entering your email, you agree to receive emails from EComaas
    </p> */}
  
    </div>
  </div>
);

const About = ({ dataC }) => (
  <div className="">
    <div className="row">
      <div className="col-md-6">
        {" "}
        <div className="b-content" dangerouslySetInnerHTML={{ __html: dataC.content }}></div>
      </div>
      <div className="col-md-6"></div>
    </div>
    <div className="image-section-aboutus">
      <img src={dataC.img} alt="" className="banner-img-spro-about" />
    </div>
  </div>
);
const Eco = ({ dataC }) => (
  <div className="row">
  <div className="col-md-12">
    <div className="b-content px-0 echo-content">{dataC.content}</div>
    <div className="sub-subtitle b-subtitle-width px-0">{dataC.subtitle1}</div>
    <div className="b-content px-0 echo-content">{dataC.content1}</div>
    <div className="sub-subtitle b-subtitle-width px-0">{dataC.subtitle2}</div>
    <div className="b-content px-0 echo-content">{dataC.content2}</div>
    <div className="sub-subtitle b-subtitle-width px-0">{dataC.subtitle3}</div>
    <div className="b-content px-0 echo-content">{dataC.content3}</div>
    <div className="sub-subtitle b-subtitle-width px-0">{dataC.subtitle4}</div>
    <div className="b-content px-0 echo-content">{dataC.content4}</div>
    {/*<div className="sub-subtitle b-subtitle-width px-0">{dataC.subtitle5}</div>
    <div className="b-content px-0 echo-content">{dataC.content5}</div>*/}
  
    <div className="image-eco-section">
      <img
        src={process.env.PUBLIC_URL + dataC.img}
        alt=""
        
      />
    </div>
  </div>
</div>
);
const Common = ({ dataC }) => (
  <div className="">
    <div className="row mx-0 ">
      <div className="b-subtitle b-subtitle-width px-0">{dataC.subtitle}</div>
    </div>
    {dataC.single ? <div className="b-content mt-0">{dataC.content}</div> : ""}
    <a href={dataC.link} className="b-button bt-common">
      {dataC.button}
    </a>
    <div className={HideImage()}>
      <img
        src={process.env.PUBLIC_URL + dataC.img}
        alt=""
        className="common-ban-img"
      />
    </div>
  </div>
);
const SingleInt = ({ dataC }) => (
  <div className="">
    <div className="">
      <div className="b-subtitle px-0">{dataC.subtitle}</div>
      <div className="b-content mt-0">{dataC.content}</div>
      <a href="#!" className="b-button ">
        {dataC.button}
      </a>
    </div>

    <div className="blur-box5-container">
      <div className="blur-box"></div>
    </div>
    <div className="image-section-sint ">
      <img src={dataC.img} alt="" className="banner-img-spro-about" />
    </div>
  </div>
);

// const renderBan=()=>{
//   if (condition) {

//   } else {

//   }
// }

const Banner = ({ dataBanner }) => (
  <section className="home-banner">
    <h1 className="banner-title">{dataBanner.title}</h1>

    {dataBanner.type === "home" ? <Home /> : ""}
    {dataBanner.type === "eco" ? <Eco dataC={dataBanner} /> : ""}
    {dataBanner.type === "about" ? <About dataC={dataBanner} /> : ""}
    {dataBanner.type === "common" ? <Common dataC={dataBanner} /> : ""}
    {dataBanner.type === "spro" ? <SinglePro dataC={dataBanner} /> : ""}
    {dataBanner.type === "single-int" ? <SingleInt dataC={dataBanner} /> : ""}

    {/*{dataBanner.type !== "single-int" ? (
      <div className="common-blur-box-content common-blur-01">
        <img
          src="../img/Vector2.svg"
          alt=""
          className="common-blur-box-defalut common-blur-1 "
        />
      </div>
    ) : (
      ""
    )}
    <div className="common-blur-box-content common-blur-02">
      <img
        src="../img/Vector1.svg"
        alt=""
        className="common-blur-box-defalut common-blur-2"
      />
  </div>*/}
  </section>
);
// renderBan.propTypes = {
//   data: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//   }).isRequired,
// };
export default Banner;

import React from "react";
import "./EcoSystem.sass";
const EcoSystem = () => (
  <div className="ecosystem">
    
    <div className="row">
      <div className="col-12 col-md-6 d-none d-md-flex">
        <img src="./images/ecosystem.svg" alt="" />
      </div>
      <div className="col-12 col-md-6">
        <div className="title">Our EcoSystem</div>
        <div className="col-12 col-md-6 mb-4  d-md-none">
          <img src="./images/ecosystem.svg" alt="" />
        </div>
        <p className="content">
        eComaaS leads e-commerce with a tailored platform for DTC merchants, 
        supported by developers, designers, and global partners. With a focus on excellence and 
        collaboration, eComaaS empowers merchants to thrive. Join our community to experience the future of 
        e-commerce firsthand.
        </p>
        <a className="readmore" href="/ecosystem" target="_blank">
          Click here to check out the tools  <i className="fa-solid fa-angle-right"></i>
        </a>
      </div>
    </div>
  </div>
);
export default EcoSystem;

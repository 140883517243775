import React from "react";
import { useState } from "react";
import serverConfig from "../../config/api.json";
import axios from "axios";
import "./Newsletter.sass";

const Home = ({ dataC }) => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({
    // email: ["Please enter a valid email"],
  });

  const handleOnSubmit = async () => {
    if (!email) {
      setErrors({ email: ["Please enter a valid email"] });
      return;
    }

    try {
      const response = await axios.post(`${serverConfig.api}/subscribe`, {
        email: email,
      });
      setSuccessMessage(response.data.message);
      setErrors({});
    } catch (error) {
      setSuccessMessage(false);
      if (error.response.status === 400) {
        setErrors(error.response.data.errors);
      } else {
        console.error(error);
      }
    }
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="NewsLetter">
      {/*<h4 className="banner-subtitle">
      eComaaS as a service. eComaaS is trusted by many business world wide.
       
  </h4>*/}
      <form className="d-flex form">
        <div className="input-container">
          <input
            className={`form-control ${errors.email ? " is-invalid" : ""} ${successMessage ? " is-valid" : ""}`}
            type="search"
            placeholder="Enter Your Email"
            onChange={handleChange}
            aria-label="Search"
          />
        </div>
        <button className="submit" type="button" onClick={handleOnSubmit}>
          Create Your Store
        </button>
      </form>
      <div>
        {successMessage && <div className="success">{successMessage}</div>}
      </div>
      <div>
        {errors.email && <div className="error">{errors.email[0]}</div>}
      </div>

      {/*<div className="mail-inform">
        by entering your email, you agree to receive emails from eComaaS.
      </div>*/}

      <div className="image-section">
        <img src="./images/homebanner1.svg" alt="" className="banner-img1" />
        <img src="./images/homebanner2.svg" alt="" className="banner-img2" />
      </div>
    </div>
  );
};

export default Home;

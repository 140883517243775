import React from "react";
import Banner from "../components/common/Banner";
import Layers from "../components/EcoSystem/Layers";
import Blog from "../components/home/Blog";
import Contact from "../components/home/Contact";
import Integration from "../components/home/Integration";
import Main from "../layouts/main";


const EcoSystem = (props) => (
  <Main title="Streamlined Multichannel eCommerce Solutions" description="Explore the power of the Ecomaas Ecosystem, your one-stop solution for efficient multichannel eCommerce management. Seamlessly navigate the complexities of online selling with our integrated platform that optimizes inventory, streamlines order processing, and enhances customer experiences.">
    <div className="eco">
    <Banner dataBanner={data} />  
    <Layers />
    <Integration />
    <Blog />
    <Contact />
    </div>
  </Main>
);
const data = {
  type: "eco",
  content: "eComaaS stands as a beacon of success in the realm of e-commerce, boasting a robust ecosystem that caters to the diverse needs of Direct-to-Consumer (DTC) merchants, a vibrant community of in-house developers and designers, and a far-reaching network of global partners. At the heart of eComaaS lies a commitment to excellence, innovation, and collaboration, driving the success of businesses across the globe.",
  title:"Our Ecosystem",
  img:"./images/banner_eco.svg",
  
  subtitle1: "Direct-to-Consumer (DTC) Merchants",
  content1: "Our platform caters specifically to the needs of Direct-to-Consumer (DTC) merchants, providing them with the tools and resources they need to thrive in today's competitive market landscape. From seamless website management to efficient order processing and delivery management, eComaaS empowers DTC merchants to build and grow their online presence with confidence.",

  subtitle2: "Community of In-House Developers and Designers",
  content2: "eComaaS boasts a vibrant community of in-house developers and designers who are dedicated to pushing the boundaries of e-commerce innovation. With their expertise and creativity, they continuously enhance our platform, ensuring that it remains at the forefront of technological advancements and user experience excellence.",
  
  subtitle3: "Global Network of Partners",
  content3: "Collaboration is at the core of eComaaS's success, and we have forged strong partnerships with leading players in various areas, including marketing, payments, shipping, reporting, and technology. By establishing direct connections with local and global partners, eComaaS enhances its core functions, providing merchants with access to best-in-class solutions and services.",

  subtitle4: "Forge a Direct Connection",
  content4: "At eComaaS, we understand the importance of forging direct connections with our partners to ensure the seamless operation of our platform. Whether it's integrating with third-party services or collaborating on joint initiatives, we work closely with our partners to solidify our core functions and deliver unparalleled value to our customers.Join our thriving community today to experience the power of our e-commerce ecosystem firsthand. Explore our platform, connect with like-minded individuals, and unlock new opportunities for growth and success with eComaaS.",

  //subtitle5: "Join the eComaaS Community",
  //content5: "Join the thriving eComaaS community and experience the power of our e-commerce ecosystem firsthand. From DTC merchants to in-house developers and global partners, together, we are shaping the future of e-commerce. Explore our platform, connect with like-minded individuals, and unlock new opportunities for growth and success with eComaaS.",
};


export default EcoSystem;

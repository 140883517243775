const brand = [
  {
    hint: "amazon",
    img: "./images/brand/amazon.svg",
    link: "",
  },
  {
    hint: "ebay",
    img: "./images/brand/ebay.svg",
    link: "",
  },
  {
    hint: "",
    img: "./images/brand/woocommerce.svg",
    link: "",
  },
  {
    hint: "shopify",
    img: "./images/brand/shopify.svg",
    link: "",
  },
  {
    hint: "xero",
    img: "./images/brand/xero.svg",
    link: "",
  },
  {
    hint: "",
    img: "./images/brand/magento.svg",
    link: "",
  },
  {
    hint: "royal",
    img: "./images/brand/royal_mail.svg",
    link: "",
  },
];

export default brand;

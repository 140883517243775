import React from "react";
import "./Demo.sass";

const Demo = () => {
  return (
    <div className="demo">
      <div className="title">Request a Demo</div>
      <div className="common-blur-box-content common-blur-05">
        <img
          src="../img/Vector2.svg"
          alt=""
          className="common-blur-box-defalut common-blur-1"
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <img
            src={process.env.PUBLIC_URL + "/images/Demo.png"}
            alt=""
            className="demo-img"
          />
        </div>
        <div className="col-md-6">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="demo-content-box">
              <div className="demo-subtitle">get a free demo</div>
              <div className="demo-content">
                We look forward to showing you how our platform’s work with
                intuitive user experience can help you connect with your
                audience at scale and gather critical insights that move your
                business forward
              </div>
              <a href="/contact/#contact-frm" className="getin">
                get in touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;

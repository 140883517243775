import React from "react";
import Banner from "../components/common/Banner";
import Contact from "../components/home/Contact";
import Plug from "../components/integration/plug";
import Main from "../layouts/main";

const Integration = () => (
  <Main title="Ecomaas Plug and Play Integrations" description="Discover Ecomaas Plug and Play Integrations on our dedicated page, offering a seamless way to enhance your multichannel eCommerce platform. Effortlessly connect and integrate your online business with a variety of powerful tools and third-party services.">
  <div className="integrations">
    <Banner dataBanner={data} />
    <Plug />
    <Contact />
  </div>
  </Main>
);
const data = {
  type: "common",
  title: "Seamless Integrations for Streamlined Workflows",
  subtitle: "",
  link: "",
  img:"./amico.svg",
  button: "Request demo",
};

export default Integration;

const integration = [
  {
    type: "single-int",
    button: "Request demo",
    id: 1,
    title: "Shopify",
    subtitle:
      "eCommerce Inventory Management and shipping for Shopify provides a full audit trail from point of sale to dispatch via your couriers",
    content:
      "eComaaS plugs straight into Shopify, no development work is required. Once connected, eComaaS delivers full inventory management for your Shopify website. List your items to Shopify in bulk, managing your item names, descriptions, pricing and images. Sales orders from Shopify are pulled into eComaaS in real time ready to process, pick and pack. eComaaS integrates with your couriers to enable courier label printing at the pack station. Bulk edit your items in Shopify and across all your eCommerce channels. eComaaS offers a full Order Management and Warehouse Management system for Shopify sellers. Connect the eComaaS ePos software to your Shopify store for real time inventory control across all your channels, instore and online.",
    content_title: "Shopify & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/shopify.svg",
    opt_img: "/images/integration_mansit.svg",
  },
  {
    type: "single-int",
    button: "Request demo",
    id: 2,
    title: "Amazon",
    subtitle:
      "eCommerce Inventory Management and shipping for Amazon provides a full audit trail from point of sale to dispatch via your couriers",
    content:
      "eComaaS plugs straight into Amazon, no development work is required. Once connected, eComaaS delivers full inventory management for your Amazon accounts. List your items to Amazon in bulk. Sales orders are pulled into eComaaS in real time ready to process, pick and pack. Bulk edit your items in Amazon. eComaaS offers a full Order Management and Warehouse Management system for Amazon.",
    content_title: "Amazon & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/amazon.svg",
    opt_img: "/images/integration_mansit.svg",
  },
  {
    type: "single-int",
    button: "Request demo",
    id: 3,
    title: "Magento",
    subtitle:
      "eCommerce Inventory Management and shipping for Magneto provides a full audit trail from point of sale to dispatch via your couriers",
    content:
      "eComaaS plugs straight into Magento 1, no development work is required. Once connected, eComaaS delivers full inventory management for your Magento 1 website. List your items to Magento 1 in bulk, managing your simple and variable items. Sales orders from Magento 1 are pulled into eComaaS in real time ready to process, pick and pack. eComaaS integrates with your couriers to enable courier label printing at the pack station. Bulk edit your items in Magento 1, including prices, descriptions, item attributes and images. eComaaS offers a full Order Management and Warehouse Management system for Magento 1 sellers.",
    content_title: "Magneto & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/magento.svg",
    opt_img: "/images/integration_mansit.svg",
  },
  {
    type: "single-int",
    button: "Request demo",
    id: 4,
    title: "Woo Commercce",
    subtitle:
      "eCommerce Inventory Management and shipping for Woo Commerce provides a full audit trail from point of sale to dispatch via your couriers",
    content:
      "eComaaS plugs into WooCommerce, no development work is required. Once connected, Stok.ly delivers full inventory management for your WooCommerce website. List your items to WooCommerce in bulk, managing all your product data from one dashboard across all your eCommerce channels. Sales orders from WooCommerce are pulled into Stok.ly in real time ready to process, pick and pack. Stok.ly integrates with your couriers to enable courier label printing at the pack station. Bulk edit your items in WooCommerce, including prices, descriptions, item attributes and images. Stok.ly offers a full Order Management and Warehouse Management system for WooCommerce sellers.",
    content_title: "Woo Commerce & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/woocommerce.svg",
    opt_img: "/images/integration_mansit.svg",
  },
  {
    type: "single-int",
    button: "Request demo",
    id: 5,
    title: "xero",
    subtitle:
      "A seamless, free integration with Xero accounting software and your ePos and eCommerce channels.",
    content:
      "eComaaS delivers a free integration with Xero accounting software, pushing all your sales invoices to Xero. No data entry or exporting CSVs, once we plug into your Xero account (no development work required), all sales are pushed into Xero against your customer accounts. All sales from ePos, eCommerce, B2C and B2B channels are pushed to Xero. Stok.ly offers a complete Order management system and warehouse management system seamlessly integrated with Xero.",
    content_title: "Xero & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/xero.svg",
    opt_img: "/images/integration_mansit.svg",
  },
  {
    type: "single-int",
    button: "Request demo",
    id: 6,
    title: "eBay",
    subtitle:
      "eCommerce Inventory Management and shipping for Shopify provides a full audit trail from point of sale to dispatch via your couriers",
    content:
      "eComaaS plugs straight into eBay, no development work is required. Once connected, Stok.ly delivers full inventory management for your eBay accounts. List your items to eBay in bulk, managing your item specifics and custom specifics. Sales orders from eBay are pulled into Stok.ly in real time ready to process, pick and pack. Stok.ly integrates with your couriers to enable courier label printing at the pack station. Bulk edit your items in eBay, including prices, descriptions, item specifics and images. Stok.ly offers a full Order Management and Warehouse Management system for eBay sellers.",
    content_title: "eBay & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/ebay.svg",
    opt_img: "/images/integration_mansit.svg",
  },
  {
    type: "single-int",
    button: "Request demo",
    id: 7,
    title: "ROYAL MAIL",
    subtitle:
      "eCommerce Inventory Management and shipping for royal mail provides a full audit trail from point of sale to dispatch via your couriers",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum turpis ultricies rhoncus egestas donec sed lacus. Amet vel venenatis id velit justo velit. Lectus at ullamcorper libero, cursus nibh. Feugiat maecenas diam, id integer velit.Pellentesque placerat nisi, arcu at ut pellentesque tincidunt lacus. Ligula suspendisse elit quam sit pellentesque at. Tortor adipiscing sed suscipit magna. Nulla pellentesque aliquam rhoncus non tristique eget ac. Orci, vel, euismod dui consequat vitae ultrices et arcu. Sed sit enim ante nulla elementum quis pulvinar.",
    content_title: "eBay & eComaaS",
    options: [
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus feugiat semper.",
      },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      { name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. " },
      {
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ligula faucibus amet.",
      },
    ],
    img: "/images/integration/royal.svg",
    opt_img: "/images/integration_mansit.svg",
  },
];

export default integration;

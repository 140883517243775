const related=[
    {
        type:"Technology",
        title:"Et ullamcorper nisl nulla.",
        date:"JAN 10, 2022",
        author:"Erin Rhiel Madsen",
        link:"",
        img:"./images/blog-recent1.svg",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque sollicitudin viverra at venenatis augue lorem. Urna euismod elementum lectus urna velit."
    },
    {
        type:"Technology",
        title:"Et ullamcorper nisl nulla.",
        date:"JAN 10, 2022",
        author:"Erin Rhiel Madsen",
        link:"",
        img:"./images/blog-recent2.svg",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque sollicitudin viverra at venenatis augue lorem. Urna euismod elementum lectus urna velit."
    },
    {
        type:"Technology",
        title:"Et ullamcorper nisl nulla.",
        date:"JAN 10, 2022",
        author:"Erin Rhiel Madsen",
        link:"",
        img:"./images/blog-recent3.svg",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque sollicitudin viverra at venenatis augue lorem. Urna euismod elementum lectus urna velit."
    },
    {
        type:"Technology",
        title:"Et ullamcorper nisl nulla.",
        date:"JAN 10, 2022",
        author:"Erin Rhiel Madsen",
        link:"",
        img:"./images/blog-recent1.svg",
        content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque sollicitudin viverra at venenatis augue lorem. Urna euismod elementum lectus urna velit."
    }
]

export default related;
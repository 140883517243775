import React from "react";
import { NavLink } from "react-router-dom";
import "./plug.sass";

const Plug = () => (
  <div className="plug">
    <div className="title ">Plug and Play Intergrations</div>
    <div className="row mt-4">
      <div className="col-md-7">
        <div className="text-center">
          <img className="img-icon" src="./images/brand/shopify.svg" alt="" />
          <div className="content">
            Shopify is an online store creation platform that operates on a
            subscription-based model, enabling individuals to effortlessly
            establish their own e-commerce websites and effectively market and
            sell their merchandise.
          </div>
        </div>
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/1"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
        <div className="text-center">
          <img className="img-icon" src="./images/brand/amazon.svg" alt="" />
          <div className="content">
            Discover the seamless integration with Amazon - effortlessly list
            and efficiently manage your products on the largest global
            marketplace. Unlock your sales potential and elevate your business
            today.
          </div>
        </div>
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/2"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
      <div className="col-md-5">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <img
            src="./images/integration.svg"
            alt=""
            className="bottom-img  d-none d-md-block"
          />
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-5">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <img
            src="./images/integration t.svg"
            alt=""
            className="bottom-img  d-none d-md-block"
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="text-center">
          <img className="img-icon" src="./images/brand/ebay.svg" alt="" />
          <div className="content">
            eBay sellers offer more categories of products than any other
            marketplace. eComaas's best-in-class eBay integration gives sellers
            maximum flexibility.
          </div>
        </div>
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/6"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
        <div className="text-center">
          <img
            className="img-icon"
            src="./images/brand/woocommerce.svg"
            alt=""
          />
          <div className="content">
            WooCommerce is a cost-free WordPress plugin designed to empower
            WordPress users in selling their products or services directly
            through their website.
          </div>
        </div>
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/4"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-md-7">
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/3"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
        <div className="text-center">
          <img className="img-icon" src="./images/brand/xero.svg" alt="" />
          <div className="content">
            Xero for WooCommerce gives you the ability to set up automatic
            creation of invoices in Xero for ecommerce sales. After the module
            is installed and configured, each order placed in your WooCommerce
            store is securely sent to your Xero account to keep records up to
            date.
          </div>
        </div>
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/5"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
        <div className="text-center">
          <img
            className="img-icon"
            src="./images/brand/royal_mail.svg"
            alt=""
          />
          <div className="content">
            Royal Mail is a reliable UK postal service offering domestic and
            international shipping solutions with tracking options and prompt
            delivery. Trusted for their professionalism, they cater to a wide
            range of shipping needs.
          </div>
        </div>
        <div className="text-end">
          <NavLink className="readmore text-end" to={"/integrations/7"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
      <div className="col-md-5">
        <div className="h-100 d-flex justify-content-center align-items-center">
          <img
            src="./images/integration 3.svg"
            alt=""
            className="bottom-img d-none d-md-block"
          />
        </div>
      </div>
    </div>

    <div className="common-blur-box-content common-blur-07">
      <img
        src="../img/Vector2.svg"
        alt=""
        className="common-blur-box-defalut common-blur-1"
      />
    </div>
  </div>
);

export default Plug;

import React from "react";
import "../static/css/navbar.sass";
import navmenu from "../data/navmenu";

const SingleLink = ({ data }) => {
  return (
    <li className="nav-item dropdown">
      <a className="nav-link" href={data.link}>
        {data.title}
      </a>
      {data.submenu && (
        <ul className="dropdown-menu">
          {data.submenu.map((subItem, subIndex) => (
            <li key={subIndex}>
              <a className="dropdown-item" href={subItem.link}>
                {subItem.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const NavBar = (props) => (
  <nav className="navbar navbar-expand-lg navbar-light fixed-top">
    <div className="container-fluid nav-padding">
      <a className="navbar-brand" href="/">
        <img src="/images/logo.png" alt={"title"} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {/* <span className="navbar-toggler-icon text-primary"></span> */}
        <span>
          {" "}
          <i className="fa-solid fa-bars"></i>
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          {navmenu.map((navlist, index) => (
            <SingleLink data={navlist} key={index}></SingleLink>
          ))}
        </ul>
      </div>
    </div>
  </nav>
);

export default NavBar;

import React from "react";
import "./Products.sass";
import products from "../../data/our_products";
import { NavLink } from "react-router-dom";
const Product = ({ productType }) => (
  <div className="products">
    {productType.type === "home" ? (
      <div className="title text-center">Our Products</div>
    ) : (
      ""
    )}
    <div className="card-section d-flex flex-wrap justify-content-center">
      {products.map((pro, index) => (
        <div
          key={index}
          className={
            productType.type === "home" ? "pcard " : "pcard product-border margin-extra"
          }
        >
          <img className="img-icon" src={pro.img} alt="" />

          <div className="sub-title">{pro.subtitle}</div>
          <div className="content">{pro.content}</div>

          <NavLink className="readmore" to={"/products/" + pro.slug}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>

          {/* <a href="#!" className="readmore"></a> */}
        </div>
      ))}
    </div>
    {productType.type === "home" ? (
      <div className="d-flex">
        <img src="./images/man.svg" alt="" className="bottom-img" />
      </div>
    ) : (
      ""
    )}
  </div>
);

export default Product;

import React from "react";
import Banner from "../components/common/Banner";
import Product from "../components/common/Product";
import Contact from "../components/home/Contact";
//import Demo from "../components/home/Demo";
import Main from "../layouts/main";

const Products = (props) => (
  <Main title="Ecomaas Multichannel eCommerce Solutions" description="Explore the Ecomaas Multichannel eCommerce Solutions Product Page, featuring a comprehensive suite of tools to revolutionize your online business. Discover the power of ePos, streamlining your point-of-sale operations.">
    <div className="product">
    <Banner dataBanner={data} />  
    <Product  productType={productData}/>
    {/*<Demo />*/}
    <Contact/>
    </div>
  </Main>
);
const data = {
  type: "common",
  title:"the best Multi-Channel, Stock Control and ePos solution ",
  subtitle:" ",
  link:" ",
  button:"Request demo",
  img:"./images/banner_product.svg"
};

const productData={
  type:"product"
}
export default Products;

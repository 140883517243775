import React from "react";
import related from "../../data/related";
import "./Related.sass";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { NavLink } from "react-router-dom";
const options = {
  loop: true,
  margin: 5,
  nav: true,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  center: true,
  navText: [
    "<i class='fa fa-angle-left'></i>",
    "<i class='fa fa-angle-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 3,
    },
  },
};

const Related = () => (
  <div className="blog  ">
    <section id="slider">
      <div className="slider">
        <OwlCarousel className="owl-carousel owl-blog" {...options}>
          {related.map((item, index) => (
            <div className="slider-card" key={index}>
              <div className="blog-section sl2">
                <div className="bl-subtitle">{item.type}</div>
                <img
                  src={process.env.PUBLIC_URL + item.img}
                  alt=""
                  className="bl-img"
                />
                <div className="bl-title">{item.title}</div>
                <div className="bl-content">{item.content}</div>
                <div className="d-flex justify-content-between  bl-content bottom-inform">
                  <div>
                    <span className="me-1">{item.date}</span>
                    <span>{item.author}</span>
                  </div>
                  <NavLink className="readmore text-end" to={"/blog/1"}>
                    Learn More <i className="fa-solid fa-angle-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
    {/* <div className="d-flex justify-content-center">
      <div className="blog-section">
        <div className="bl-subtitle">Technology</div>
        <img src="./images/blog-img1.svg" alt="" className="bl-img" />
        <div className="bl-title">Et ullamcorper nisl nulla.</div>
        <div className="bl-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque
          sollicitudin viverra at venenatis augue lorem. Urna euismod elementum
          lectus urna velit.
        </div>
        <div className="d-flex justify-content-between  bl-content bottom-inform">
          <div>
            {" "}
            <span className="me-1">JAN 10, 2022</span>{" "}
            <span>Erin Rhiel Madsen</span>
          </div>
          <a className="readmore">
            Learn More <i class="fa-solid fa-angle-right"></i>
          </a>
        </div>
      </div>
      <div className="blog-section ">
        <div className="bl-subtitle">Technology</div>
        <img src="./images/blog-img1.svg" alt="" className="bl-img" />
        <div className="bl-title">Et ullamcorper nisl nulla.</div>
        <div className="bl-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque
          sollicitudin viverra at venenatis augue lorem. Urna euismod elementum
          lectus urna velit.
        </div>
        <div className="d-flex justify-content-between  bl-content bottom-inform">
          <div>
            <span className="me-1">JAN 10, 2022</span>{" "}
            <span>Erin Rhiel Madsen</span>
          </div>
          <a className="readmore">
            Learn More <i className="fa-solid fa-angle-right"></i>
          </a>
        </div>
      </div>
      <div className="blog-section ">
        <div className="bl-subtitle">Technology</div>
        <img src="./images/blog-img1.svg" alt="" className="bl-img" />
        <div className="bl-title">Et ullamcorper nisl nulla.</div>
        <div className="bl-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque
          sollicitudin viverra at venenatis augue lorem. Urna euismod elementum
          lectus urna velit.
        </div>
        <div className="d-flex justify-content-between  bl-content bottom-inform">
          <div>
            <span className="me-1">JAN 10, 2022</span>{" "}
            <span>Erin Rhiel Madsen</span>
          </div>
          <a className="readmore">
            Learn More <i className="fa-solid fa-angle-right"></i>
          </a>
        </div>
      </div>
    </div> */}
  </div>
);

export default Related;

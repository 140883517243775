import React from "react";
import Banner from "../components/common/Banner";
import Blog from "../components/home/Blog";
import Contact from "../components/home/Contact";
import Demo from "../components/home/Demo";
import EcoSystem from "../components/home/EcoSystem";
import Integration from "../components/home/Integration";
import Product from "../components/common/Product";
import Retailers from "../components/home/Retailers";
import Testimonial from "../components/home/Testimonial";
import Main from "../layouts/main";
import serverConfig from "../config/api.json";
import axios from "axios";

const Index = (props) => (
  <Main title="ePos,Stock Control and Multichannel" description="Managing Multichannel eCommerce platform for retailers never been easier than when you are using eComaaS systems – ePos, Website,Third party platform integration, Stock Control, Payment Gateway, Sales report analysis and eCommerce Management all-in-one!
  eComaaS system and services support growing retailers who want to expand and larger businesses managing more complex retail operations and you can easily manage stock levels across Multichannel eCommerce platform.">
    <Banner dataBanner={data} />
    <Retailers />
    <EcoSystem />
    <Product productType={productData} />
    <Integration />
    <Demo />
    <Testimonial data={data2} />
    <Blog />
    <Contact />
  </Main>
);

const fetchTestimonialData = async () => {
  let data = [];
  try {
    const response = await axios.get(`${serverConfig.api}/testimonials`);
    data = response.data.data;
    //setTestimonial(data);
    return data;
  } catch (error) {
    console.error("Error fetching testimonial data:", error);
    return data;
  }
};

const data2 = await fetchTestimonialData();

const data = {
  type: "home",
  title: "Build Your Own ecommerce website in 3 easy steps",
};

const productData = {
  type: "home",
};

export default Index;

import React from "react";
import Main from "../layouts/main";
import integration from "../data/integration";
import Description from "../components/SingleIntegration/Dscription";
import { useParams } from "react-router-dom";
import Banner from "../components/common/Banner";
import Integration from "../components/home/Integration";
import Demo from "../components/home/Demo";
import Contact from "../components/home/Contact";

const SingleIntegration = () => {
  let { id } = useParams();
  let index = integration.findIndex((e) => e.id === parseInt(id));

  let data = integration[index];
  return (
    <Main>
      <Banner dataBanner={data} />
      <div className="s-integration">
        <Description desData={data} />
        <Integration />
        <Demo />
        <Contact />
      </div>
    </Main>
  );
};

export default SingleIntegration;

import React from "react";
import "./Single.sass";

const Single = ({ singleData }) => (
  <div className="single-blog-section position-relative">
    <h1 className="banner-title">{singleData.title}</h1>

    <div className="sb-contents">
      <div className="sb-img-section">
        <img src={singleData.img} alt="" className="img-b" />
        <div className="blur-box-container-sblog common-blur-box-content">
          <img
            src="../img/Vector2.svg"
            alt=""
            className="common-blur-box-defalut"
          />
        </div>
      </div>
      <p>
        Do you currently sell online and offline, or perhaps you’re thinking of
        making that all-important integration between your online and your
        standalone brick-and-mortar shop?Thousands of businesses are already
        taking advantage of the power of an effective EPOS (Electronic
        Point-of-Sale) system to help grow their businesses, and so should
        you.EPOS systems are a combination of hardware and software and can help
        make a huge difference to your website once integrated with your online
        physical store. The software tracks your sales, inventory, and customer
        base, helping you make more informative and efficient business decisions
        by generating detailed reports in response to customer interactions with
        your store. Whereas the hardware accounts for the till system, payment
        terminals, barcode and QR code scanners supported by this software.
        Where traditional till systems simply record a sale, EPOS systems go
        that step further by recording an extensive backlog of detailed
        information helping you understand your customer base and their buying
        habits, offering an efficient inventory and accounts database, allowing
        you to build an effective business strategy supported by valuable
        methodical data.
      </p>
      <p>
        Do you currently sell online and offline, or perhaps you’re thinking of
        making that all-important integration between your online and your
        standalone brick-and-mortar shop?Thousands of businesses are already
        taking advantage of the power of an effective EPOS (Electronic
        Point-of-Sale) system to help grow their businesses, and so should
        you.EPOS systems are a combination of hardware and software and can help
        make a huge difference to your website once integrated with your online
        physical store. The software tracks your sales, inventory, and customer
        base, helping you make more informative and efficient business decisions
        by generating detailed reports in response to customer interactions with
        your store. Whereas the hardware accounts for the till system, payment
        terminals, barcode and QR code scanners supported by this software.
        Where traditional till systems simply record a sale, EPOS systems go
        that step further by recording an extensive backlog of detailed
        information helping you understand your customer base and their buying
        habits, offering an efficient inventory and accounts database, allowing
        you to build an effective business strategy supported by valuable
        methodical data.
      </p>
      <p>
        Do you currently sell online and offline, or perhaps you’re thinking of
        making that all-important integration between your online and your
        standalone brick-and-mortar shop?Thousands of businesses are already
        taking advantage of the power of an effective EPOS (Electronic
        Point-of-Sale) system to help grow their businesses, and so should
        you.EPOS systems are a combination of hardware and software and can help
        make a huge difference to your website once integrated with your online
        physical store. The software tracks your sales, inventory, and customer
        base, helping you make more informative and efficient business decisions
        by generating detailed reports in response to customer interactions with
        your store. Whereas the hardware accounts for the till system, payment
        terminals, barcode and QR code scanners supported by this software.
        Where traditional till systems simply record a sale, EPOS systems go
        that step further by recording an extensive backlog of detailed
        information helping you understand your customer base and their buying
        habits, offering an efficient inventory and accounts database, allowing
        you to build an effective business strategy supported by valuable
        methodical data.
      </p>
    </div>
    <div className="date-b">
      <span>{singleData.author}</span> <span>{singleData.date}</span>
    </div>
    <div className="blur-box-container-sblog2 common-blur-box-content">
      <img
        src="../img/Vector2.svg"
        alt=""
        className="common-blur-box-defalut"
      />
    </div>
  </div>
);
export default Single;

import React from "react";
import "./Integration.sass";
import brand from "../../data/brand";

/*const HideIntImage = () => {
  const location = window.location.pathname;
  return location !== "/" ? "int-img d-none" : "int-img";
};*/

const Integration = () => (
  <div className="integration">
    <div className="title">Integrations</div>
    <div className="d-flex flex-wrap justify-content-center">
      {brand.map((b, index) => (
        <div key={index} className={"brand " + b.hint}>
          <img src={b.img} alt="" className="b-img" />
        </div>
      ))}
    </div>
    <div className="blur-box-container-int">
      <div className="row">
        <div className="col-md-6"></div>
        <div className="common-blur-box-content common-blur-04">
          <img
            src="../img/Vector2.svg"
            alt=""
            className="common-blur-box-defalut common-blur-1"
          />
        </div>
      </div>
    </div> 
  </div>
);
export default Integration;

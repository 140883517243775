import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSquareXTwitter} from "@fortawesome/free-brands-svg-icons";


const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
};


const Footer = (props) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="">
      <div className="footer d-none d-md-block">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex justify-content-content">
             <a href="/">
              <img src="../images/logo.png" alt="" className="fotter-logo" />
             </a>
            </div>
            <div className="social-icons">
              <a href="https://www.facebook.com/eComaasx" target="blank">
                <i className="fab fa-facebook-square"></i>
              </a>
              <a href="https://www.linkedin.com/company/e-comaass/" target="blank">
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://www.instagram.com/ecomaasxx/"  target="blank">
                <i class="fab fa-instagram-square"></i>
              </a>
              <a href="https://twitter.com/ecomaasx" target="blank">
                <FontAwesomeIcon icon={faSquareXTwitter} />
              </a>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-4">
                <div className="f-title">Products</div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/epos-solutions"} onClick={scrollToTop}>
                    ePos Solutions
                  </NavLink>
                  {/* ePos */}
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/stock-management"} onClick={scrollToTop}>
                    Stock Management
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/ecommerce"} onClick={scrollToTop}>
                    eCommerce
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/multi-channel-stores"} onClick={scrollToTop}>
                    Multi Channel Stores
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/delivery-management"} onClick={scrollToTop}>
                    Delivery Management
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/reporting-and-forcasting"} onClick={scrollToTop}>
                    Reporting & Forcasting
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/payment-gateway"} onClick={scrollToTop}>
                    Payment Gateways
                  </NavLink>
                </div>
              </div>
              <div className="col-4">
                <div className="f-title">Company</div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/ecosystem"} onClick={scrollToTop}>
                    Ecosystem
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/about"} onClick={scrollToTop}>
                    About Us
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/Integrations"} onClick={scrollToTop}>
                    Integrations
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/pricing"} onClick={scrollToTop}>
                    Pricing
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/contact"} onClick={scrollToTop}>
                    Contact us
                  </NavLink>
                </div>
              </div>
              {/* <div className="col-4">
              <div className="f-title">Resources</div>
              <div className="f-c">FAQ</div>
              <div className="f-c">
                <NavLink className="readmore" to={"/blog"}>
                  Blog
                </NavLink>
              </div>
            </div> */}
            <div className="col-4">
              <div className="f-title">Address</div>
              <div className="f-sub-title">London</div>
              <div className="f-c">#32 Spring Street, Paddington W21JA.</div>
              <div className="f-c">Phone : 
                <a href="tel:+44 203 5001380"> +44 203 5001380</a>
              </div>
              <div className="f-c">Email : 
                <a href="mailto:contact@ecomaas.co"> contact@ecomaas.co</a>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="copyrights">
          &copy; <span>COPYRIGHTS {currentYear}</span>
        </div>
      </div>
      <div className="footer d-md-none">
        <div className="row">
          <div className="col-12">
            <div className="h-100 d-flex justify-content-content align-items-center">
              <a href="/" className="fotter-logo"><img src="/images/logo.png" alt="title"/></a>
            </div>
          </div> 
          <div className="col-12 social-icons">
            <div className="h-100 d-flex justify-content-content align-items-center social-icons">
              <a href="https://www.facebook.com/eComaasx" target="blank">
                <i className="fab fa-facebook-square"></i>
              </a>
              <a href="https://www.linkedin.com/company/e-comaass/" target="blank">
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://www.instagram.com/ecomaasxx/" className="ins" target="blank">
                <i class="fab fa-instagram-square"></i>
              </a>
              <a href="https://twitter.com/ecomaasx" className="ins" target="blank">
                <FontAwesomeIcon icon={faSquareXTwitter} />
              </a>
            </div>
          </div>
          
          
          <div className="col-12 pt-3">
            <div className="px-2 text-center">
              <a
                className="f-title"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Products
              </a>
              <div className="collapse" id="collapseExample">
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/epos"}>
                    ePos Solutions
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/stock-management"} onClick={scrollToTop}>
                    Stock Management
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/ecommerce"} onClick={scrollToTop}>
                    eCommerce
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/multi-channel-stores"} onClick={scrollToTop}>
                    Multi Channel Stores
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/delivery-management"} onClick={scrollToTop}>
                    Delivery Management
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/reporting-and-forcasting"} onClick={scrollToTop}>
                    Reporting & Forcasting
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/products/payment-gateway"} onClick={scrollToTop}>
                    Payment Gateways
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="px-2 text-center">
              <a
                className="f-title"
                data-bs-toggle="collapse"
                href="#collapseExample1"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Company
              </a>
              <div className="collapse" id="collapseExample1">
                <div className="f-c">
                  <NavLink className="readmore" to={"/ecosytsem"} onClick={scrollToTop}>
                    Ecosystem
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/about"} onClick={scrollToTop}>
                    About Us
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/Integrations"} onClick={scrollToTop}>
                    Integrations
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/pricing"} onClick={scrollToTop}>
                    Pricing
                  </NavLink>
                </div>
                <div className="f-c">
                  <NavLink className="readmore" to={"/contact"} onClick={scrollToTop}>
                    Contact us
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="px-2 text-center">
              <a
                className="f-title"
                data-bs-toggle="collapse"
                href="#collapseExample2"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Address
              </a>
              <div className="collapse" id="collapseExample2">
                <div className="f-sub-title">London</div>
                <div className="f-c">#32 Spring Street, Paddington W21JA.</div>
                <div className="f-c">Phone : 
                  <a href="tel:+44 203 5001380"> +44 203 5001380 </a>
                </div>
                <div className="f-c">Email : 
                  <a href="mailto:contact@ecomaas.co"> contact@ecomaas.co</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="copyrights">
          &copy; <span>COPYRIGHTS {currentYear}</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { NavLink } from "react-router-dom";
import "./Blog.sass";
const Blog = () => (
  <div className="blog">
    <div className="title">BLog</div>
    <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
      <div className="blog-section">
        <div className="bl-subtitle">Technology</div>
        <img src="./images/blog-img1.svg" alt="" className="bl-img" />
        <div className="bl-title">Et ullamcorper nisl nulla.</div>
        <div className="bl-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque
          sollicitudin viverra at venenatis augue lorem. Urna euismod elementum
          lectus urna velit.
        </div>
        <div className="d-flex justify-content-between  bl-content bottom-inform">
          <div>
            {" "}
            <span className="me-1">JAN 10, 2022</span>{" "}
            <span>Erin Rhiel Madsen</span>
          </div>
          <NavLink className="readmore" to={"blog/1"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
      <div className="blog-section bl-sapce1">
        <div className="bl-subtitle">Technology</div>
        <img src="./images/blog_img2.svg" alt="" className="bl-img" />
        <div className="bl-title">
          Sed scelerisque pulvinar velit sagittis porttitor erat.
        </div>
        <div className="bl-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque
          sollicitudin viverra at venenatis augue lorem. Urna euismod elementum
          lectus urna velit.
        </div>
        <div className="d-flex justify-content-between  bl-content bottom-inform">
          <div>
            {" "}
            <span className="me-1">JAN 10, 2022</span>{" "}
            <span>Erin Rhiel Madsen</span>
          </div>
          <NavLink className="readmore" to={"blog/1"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
      <div className="blog-section bl-sapce2">
        <div className="bl-subtitle">Technology</div>
        <img src="./images/blog_img3.svg" alt="" className="bl-img" />
        <div className="bl-title">
          Blandit praesent leo fermentum suspendisse.
        </div>
        <div className="bl-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, quisque
          sollicitudin viverra at venenatis augue lorem. Urna euismod elementum
          lectus urna velit.
        </div>
        <div className="d-flex justify-content-between  bl-content bottom-inform">
          <div>
            {" "}
            <span className="me-1">JAN 10, 2022</span>{" "}
            <span>Erin Rhiel Madsen</span>
          </div>
          <NavLink className="readmore" to={"blog/1"}>
            Learn More <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
    </div>
    <div className="blur-box-container-blog common-blur-box-content">
      <img
        src="../img/Vector2.svg"
        alt=""
        className="common-blur-box-defalut"
      />
    </div>
    <img
      src="./images/b-back-img.png"
      alt=""
      className="bl-back-img d-none d-md-block"
    />
  </div>
);
export default Blog;

import "./App.sass";

import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/index";
import EcoSystem from "./pages/EcoSystem";
import Products from "./pages/Products";
import Integrations from "./pages/Integrations";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";
import AboutUs from "./pages/AboutUs";
import SingleBlog from "./pages/SingleBlog";
import SingleProduct from "./pages/SingleProduct";
import SingleIntegration from "./pages/SingleIntegration";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const { PUBLIC_URL } = process.env;
// const About = lazy(() => import('./pages/About'));
// const Contact = lazy(() => import('./pages/Contact'));
// const Index = lazy(() => import("./pages/index"));
// const NotFound = lazy(() => import('./pages/NotFound'));
// const Projects = lazy(() => import('./pages/Projects'));
// const Resume = lazy(() => import('./pages/Resume'));
// const Stats = lazy(() => import('./pages/Stats'));
function App() {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Suspense fallback={<div>Loading</div>}>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/ecosystem" element={<EcoSystem></EcoSystem>} />
          <Route path="/products" element={<Products></Products>} />
          <Route path="/integrations" element={<Integrations></Integrations>} />
          <Route path="/pricing" element={<Pricing></Pricing>} />
          <Route path="/contact" element={<ContactUs></ContactUs>} />
          <Route path="/blog" element={<Blog></Blog>} />
          <Route path="/about" element={<AboutUs></AboutUs>} />
          <Route path="/blog/:id" element={<SingleBlog></SingleBlog>} />
          <Route path="/products/:slug" element={<SingleProduct></SingleProduct>} />
          <Route path="/integrations/:id" element={<SingleIntegration></SingleIntegration>} />
        </Routes>
      </Suspense>
      <WhatsAppWidget
          phoneNo="+447734309068"
          position="right"
          widgetWidth="300px"
          widgetWidthMobile="260px"
          autoOpen={true}
          autoOpenTimer={100000}
          messageBox={true}
          messageBoxTxt=""
          iconSize="40"
          iconColor="white"
          iconBgColor="#2D2ABC"
          headerIcon="../images/whatsapp.png"
          headerIconColor="#2D2ABC"
          headerTxtColor="white"
          headerBgColor="#2D2ABC"
          headerTitle="eComaas"
          headerCaption="Online"
          bodyBgColor="#eaeafb"
          chatPersonName="eComaas Chat Bot"
          chatMessage={<>Hello!<br />How can I help you?</>}
          footerBgColor="#c0c0f2"
          placeholder="Type a message.."
          btnBgColor="#2D2ABC"
          btnTxt="Start Chat"
          btnTxtColor="white"
            />    
    </BrowserRouter>
    //   <BrowserRouter basename={PUBLIC_URL}>
    //   <Suspense fallback={<div>loading .....</div>}>
    //     <Routes>
    //     {/* <Route exact path="/" element={ <Home />} /> */}
    //     <Route exact path="/" component={Index} />
    //       {/* <Route exact path="/" component={Index} />
    //       <Route path="/about" component={About} />
    //       <Route path="/projects" component={Projects} />
    //       <Route path="/stats" component={Stats} />
    //       <Route path="/contact" component={Contact} />
    //       <Route path="/resume" component={Resume} /> */}
    //       {/* <Route component={NotFound} status={404} /> */}
    //     </Routes>
    //   </Suspense>
    // </BrowserRouter>
  );
}

export default App;

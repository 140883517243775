import React from "react";
import Single from "../components/SingleBlog/Single";
import Main from "../layouts/main";
import { useParams } from "react-router-dom";
import blog_data from "../data/blog_data";

function SingleBlog() {
  let {id} = useParams();
  let index = blog_data.findIndex(e => e.id === parseInt(id));
  let data = blog_data[index];
 

  return (
    <Main>
      <div className="singleblog"></div>
      <Single singleData={data} />
    </Main>
  );
}

export default SingleBlog;
